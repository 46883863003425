<template>
  <div class="grid grid-cols-2 gap-2">
    <div class="grid-cols-1">
      <p class="text-black font-bold text-xl">{{details.title}}</p>
      <p class="text-starryNight mt-2">When you <span class="font-semibold">Complete listing</span> it will be active on Owayy shortly after.</p>
      <button
        class="btn-complete-section mt-2 w-3/4"
        :disabled="isListingCreated"
        @click="createProduct">
        <p v-if="isListingCreated" class="font-semibold flex justify-center gap-2 align-middle items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          Success
        </p>
        <LoadingSpinner v-if="loading" class="w-5 h-5" outer-class="text-white" />
        <span v-else-if="!isListingCreated" class="font-semibold">Complete listing</span>
      </button>
    </div>
    <ListingPreview class="col-span-1" :detail='details' />
  </div>
</template>

<script>
import ListingPreview from "@/components/UI/Card/ListingPreview";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useMutation } from "@vue/apollo-composable";
import { CREATE_PRODUCT } from "@/graphql/products/mutations";
import { useRouter } from "vue-router";

export default {
  name: "CompleteListing",
  components: {
    ListingPreview,
    LoadingSpinner
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const dataToCreate = ref(null);
    const isListingCreated = ref(false);

    const currency = store.getters["product/getCurrentCurrencyCode"]

    onMounted(() => {
      const data = store.getters["product/getInfoForCreate"];
      dataToCreate.value = data;
    })

    const infoForCreate = computed(() => store.getters["product/getInfoForCreate"]);

    const details = ref({
      img: store.state.product.product.medias.find((media) => media.position === 1).file,
      title: store.state.product.product.generalInfo.title,
      description: store.state.product.product.generalInfo.subtitle,
      currency,
      price: store.state.product.product.children[0].default_price
    });

    const { mutate: createProduct, loading, onDone } = useMutation(CREATE_PRODUCT,() => ({
      variables: {
        data: dataToCreate.value
      }
    }));

    onDone(() => {
      isListingCreated.value = true;
      setTimeout(() => {
        store.commit('product/setInitialCreateState');
        store.commit('product/setInitialState');
        router.push({ name: 'Listings' });
      }, 500)
    })

    return {
      details,
      createProduct,
      loading,
      infoForCreate,
      isListingCreated
    }
  }
};
</script>

<style scoped>

</style>
