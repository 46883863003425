<template>
  <SectionWrapper
    title="Details for booking"
    description="Select check in and check out time."
    :is-hidden="false"
    :body-padding="false"
    :isValidSection="isTouched"
    :loading="isUpdating"
    @saveSection="updateCheckInOut">
    <div class="border-t md:flex divide-y md:divide-y-0 md:divide-x">
      <div class="w-full md:w-1/2 p-4 pb-8 md:px-6">
        <div>
          <div class="flex items-center justify-between mb-3">
            <h2 class="block text-base font-medium text-gray-700">Check in time</h2>
          </div>
          <Listbox key="checkIn" v-model="dataCheckInOut.checkIn" class="min-w-lg" as="div">
            <div class="relative mt-1">
              <ListboxButton
                class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500 focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm">
                <span class="block truncate text-left">{{ dataCheckInOut.checkIn }}</span>
                <span
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ListboxOptions
                  v-if="checkInHours.length > 0"
                  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ListboxOption
                    v-slot="{ active, selected }"
                    v-for="time in checkInHours"
                    :key="time"
                    :value="time"
                    as="template">
                    <li
                      :class="['group relative cursor-default select-none py-2 pl-3 pr-9',
                               active ? 'bg-highlight-600 text-white' : 'text-gray-900']"
                      @click="setTouch">
                      <span
                        :class="[
                          selected ? 'font-medium' : 'font-normal',
                          'block truncate',
                        ]">
                        {{ time }}
                      </span>
                      <span
                        v-if="selected"
                        :class="['absolute inset-y-0 right-0 flex items-center pr-4',
                                 active ? 'text-white' : 'text-highlight-600']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-4 pb-8 md:px-6">
        <div>
          <div class="flex items-center justify-between mb-3">
            <h2 class="block text-base font-medium text-gray-700">Check out time</h2>
          </div>
          <Listbox key="checkOut" v-model="dataCheckInOut.checkOut" class="min-w-lg" as="div">
            <div class="relative mt-1">
              <ListboxButton
                class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500 focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm">
                <span class="block truncate text-left">{{ dataCheckInOut.checkOut }}</span>
                <span
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ListboxOptions
                  v-if="checkOutHours.length > 0"
                  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ListboxOption
                    v-slot="{ active, selected }"
                    v-for="time in checkOutHours"
                    :key="time"
                    :value="time"
                    as="template">
                    <li
                      :class="['group relative cursor-default select-none py-2 pl-3 pr-9',
                               active ? 'bg-highlight-600 text-white' : 'text-gray-900']"
                      @click="setTouch">
                      <span
                        :class="[
                          selected ? 'font-medium' : 'font-normal',
                          'block truncate',
                        ]">
                        {{ time }}
                      </span>
                      <span
                        v-if="selected"
                        :class="['absolute inset-y-0 right-0 flex items-center pr-4',
                                 active ? 'text-white' : 'text-highlight-600']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>
      </div>
    </div>
  </SectionWrapper>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import { UPDATE_PRODUCT } from "@/graphql/products/mutations";
import { useRoute, useRouter } from "vue-router";
import { useMutation } from "@vue/apollo-composable";

const hours = () => {
  const arr = []; let i; let j;
  for(i=0; i<24; i += 1) {
    for(j=0; j<2; j += 1) {
      arr.push(`${i  }:${  j===0 ? "00" : 30*j}` );
    }
  };
  return arr;
}

export default {
  name: "CheckInOut",
  components: { SectionWrapper },
  emits: ['updateData', 'checkIsUnsaved'],
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const nextCreateStep = computed(() => store.getters["product/getNextStepCreate"]);

    const checkInHours = ref(hours());
    const isCheckInEditable = ref(false);
    const isCheckOutEditable = ref(false);
    const checkInOut = computed(() => store.state.product.product.checkInOut);

    const dataCheckInOut = ref({
      checkIn: checkInOut.value.checkIn ?? 'Select check-in time',
      checkOut: checkInOut.value.checkOut ?? 'Select check-out time'
    });
    const isUpdating = ref(false);
    const isTouched = ref(false);

    const checkOutHours = computed(() => checkInHours.value.slice(0, checkInHours.value.indexOf(dataCheckInOut.value.checkIn)));

    watch(dataCheckInOut, (value) =>  emit('updateData', 'checkInOut', value), {
      deep: true
    });

    watch(() => dataCheckInOut.value.checkIn, () => {
      dataCheckInOut.value.checkOut = "Select check-out time";
      isTouched.value = false;
    });

    const setTouch = () => {
      emit('checkIsUnsaved', {
        title: 'CheckIn and CheckOut'
      }, true);
      isTouched.value = true;
    };

    const { mutate: updateProduct, onDone } = useMutation(UPDATE_PRODUCT, () => ({
      variables: {
        id: +route.params.id,
        data: {
          check_in: dataCheckInOut.value.checkIn,
          check_out: dataCheckInOut.value.checkOut
        }
      }
    }));

    onDone((res) => {
      const { data: { updateProduct: product } } = res;
      store.commit('product/setProduct', { product });
      isUpdating.value = false;
      isTouched.value = false;
      emit('checkIsUnsaved', {
        title: 'CheckIn and CheckOut'
      }, false);
    });

    const updateCheckInOut = async () => {
      const isCreateProductMode = store.state.product.mode === 'create';
      isUpdating.value = true;
      if (isCreateProductMode) {
        isUpdating.value = false;
        isTouched.value = false;
        emit('checkIsUnsaved', {
          title: 'CheckIn and CheckOut'
        }, false);
        store.commit('product/setData', {
          field: 'checkInOut',
          step: 'checkInOut',
          data: {
            checkIn: dataCheckInOut.value.checkIn,
            checkOut: dataCheckInOut.value.checkOut
          }
        });
        await router.push(nextCreateStep.value)
      } else {
        await updateProduct().then((res) => {
          const { data: { updateProduct: product } } = res;
          store.commit('product/setProduct', { product });
        }).finally(() => {
          isUpdating.value = false;
          isTouched.value = false;
          emit('checkIsUnsaved', {
            title: 'CheckIn and CheckOut'
          }, false);
        });
      }
    }

    return {
      hours: hours(),
      setTouch,
      updateCheckInOut,
      isCheckInEditable,
      isCheckOutEditable,
      checkOutHours,
      isUpdating,
      isTouched,
      dataCheckInOut,
      checkInHours
    }
  }
};
</script>

