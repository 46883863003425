<template>
  <div class="desc-section">
    <SectionWrapper
      title="Letter for guests"
      description="Give instructions for check in, tips on secret places to visit nearby or just a warm welcome message. This will be sent to your guests upon booking. <a class='relative cursor-pointer rounded-md font-medium text-highlight-600 hover:text-highlight-500' target='_blank' href='https://owayy.slab.com/public/posts/📝-how-to-make-the-perfect-welcome-letter-98l7tbrv'>Get Inspiration</a>"
      :isValidSection="isValidSection"
      :body-padding="false"
      :info="errors.welcomeLetter"
      :loading="isSubmitting"
      :show-skip-button="isCreateProductMode"
      @saveSection="submit">
      <form action="#" class="relative border-t">
        <div class="peer">
          <QuillEditor
            :content="welcomeLetter"
            :toolbar="[
              ['bold', 'italic'],
              [{ list: 'ordered' }, { list: 'bullet' }]
            ]"
            contentType="html"
            theme="snow"
            @update:content="handleWelcomeLetter" />
        </div>
        <div
          v-if="!isCreateProductMode"
          class="
            peer-focus-within:hidden
            absolute
            pointer-events-none
            w-full
            h-14
            bottom-0
            bg-gradient-to-t
            from-white
            via-white
          "></div>
        <div
          v-if="!isCreateProductMode"
          class="absolute top-12 right-1.5 cursor-pointer pointer-events-none peer-focus-within:hidden">
          <button class="btn btn-xs btn-white">
            <PencilAltIcon class="h-3 w-3 text-gray-600 mr-1 -ml-1" />
            Edit
          </button>
        </div>
      </form>
    </SectionWrapper>
  </div>
</template>
<script>
import { computed, watch, ref } from "vue";
import { PencilAltIcon } from "@heroicons/vue/solid";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import * as yup from "yup";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";

export default {
  name: "WelcomeLetter",
  components: {
    SectionWrapper,
    QuillEditor,
    PencilAltIcon
  },
  setup(_, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const store = useStore();
    const initialWelcomeLetter = computed(() => store.state.product.product.welcomeLatter);
    const nextCreateStep = computed(() => store.getters["product/getNextStepCreate"]);
    const isCreateProductMode = computed(() => store.state.product.mode === "create");

    const isEmpty = (htmlString) => {
      const parser = new DOMParser();

      const { textContent } = parser.parseFromString(htmlString, "text/html").documentElement;

      return textContent.trim();
    };

    const { errors, values, handleSubmit, isSubmitting, submitCount } = useForm({
      initialValues: {
        welcomeLetter: initialWelcomeLetter.value
      },
      validationSchema: {
        welcomeLetter: yup.string()
      }
    });

    const { value: welcomeLetter } = useField("welcomeLetter");

    const isDescriptionDirty = useIsFormDirty();
    const isDescriptionValid = useIsFormValid();

    const isValidSection = computed(() => isDescriptionDirty.value && isDescriptionValid.value);

    watch(isDescriptionValid, (value) => {
      if (isCreateProductMode.value) {
        emit("checkIsValid", "welcomeLatter", value);
      }
    });

    const isUnsaved = computed(() => isDescriptionDirty.value && submitCount.value === 0);

    watch(isUnsaved, (newValue) => {
      emit(
        "checkIsUnsaved",
        {
          title: "Welcome Latter"
        },
        newValue
      );
    });

    emit("updateData", "welcomeLatter", initialWelcomeLetter.value);

    watch(values, (value) => {
      emit("updateData", "welcomeLatter", value.welcomeLetter);
    });

    const isFirstInit = ref(true);

    const handleWelcomeLetter = (content) => {
      if (!isFirstInit.value) {
        if (content !== initialWelcomeLetter.value) {
          welcomeLetter.value = content;
        }
      } else {
        isFirstInit.value = false;
      }
    };

    const submit = handleSubmit(async ({ welcomeLetter }, { resetForm }) => {
      if (isValidSection.value) {
        const reset = () => {
          resetForm({
            touched: false,
            values: {
              welcomeLetter: initialWelcomeLetter.value
            }
          });
        };

        const preparedData = {
          emailDescription: isEmpty(welcomeLetter) ? welcomeLetter : ""
        };

        if (isCreateProductMode.value) {
          store.commit("product/setData", {
            field: "welcomeLatter",
            step: "welcomeLatter",
            data: isEmpty(welcomeLetter) ? welcomeLetter : ""
          });
          reset();
          await router.push(nextCreateStep.value);
        } else {
          await store.dispatch("product/updateProduct", { id: +route.params.id, data: preparedData }).then(() => {
            reset();
          });
        }
      } else if (isCreateProductMode.value) {
        store.commit("product/setData", {
          field: "welcomeLatter",
          step: "welcomeLatter",
          data: isEmpty(welcomeLetter) ? welcomeLetter : ""
        });
        await router.push(nextCreateStep.value);
      }
    });

    return {
      isValidSection,
      welcomeLetter,
      isSubmitting,
      errors,
      submit,
      handleWelcomeLetter,
      isCreateProductMode
    };
  }
};
</script>

<style scoped>
.desc-section :deep(.ql-toolbar) {
  @apply border-0 border-b;
}

.desc-section :deep(.ql-snow.ql-toolbar button),
.desc-section :deep(.ql-snow .ql-toolbar button) {
  @apply rounded;
}

.desc-section :deep(.ql-picker-label) {
  @apply rounded border-0;
}

.desc-section :deep(.ql-container) {
  @apply border-0;
}

.desc-section :deep(.ql-editor) {
  @apply md:mx-4 md:my-2 border-0 h-auto text-base prose max-w-none md:px-6 md:pt-5 md:pb-4 h-52 focus:min-h-0 overflow-hidden focus:overflow-auto opacity-60 focus:opacity-100;
}

.desc-section :deep(.ql-editor.ql-blank::before) {
  @apply focus:h-auto overflow-hidden focus:overflow-auto opacity-60 focus:opacity-100;
}

/*
.desc-section >>> .ql-toolbar {
  @apply hidden peer-focus-within:block;
}
*/
</style>
