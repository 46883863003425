<template>
  <div>
    <CheckInOut
      id="check-in-and-out"
      @checkIsUnsaved="checkForUnsavedSections"
      @updateData="setSectionsData" />

    <WelcomeLetter
      id="welcome-letter"
      @checkIsUnsaved="checkForUnsavedSections"
      @checkIsValid="setSectionsValidity"
      @updateData="setSectionsData" />

    <UnsavedChangesModal
      :isOpen="isModalOpen"
      :isInvalidSectionsPresent="isInvalidSectionsPresent"
      @toggleModal="cancelModal"
      @continue="moveToNextRoute"
      @saveAndContinue="save">
      <template v-slot:title>
        <span class="text-gray-800">
          You have unsaved changes.
        </span>
        <ul class="hidden text-sm text-water font-normal">
          <li v-for="section in unsavedSections" :key="section">
            {{ section.title }}
          </li>
        </ul>
      </template>
      <template v-slot:content>
        <p class="leading-6">You are about to exit the page. <br />All unsaved changes will be lost.</p>
      </template>
      <template v-slot:buttonName>
        Discard
      </template>
    </UnsavedChangesModal>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref } from "vue";
import WelcomeLetter from "@/components/views/Listing/InfoForGuests/WelcomeLetter";
import CheckInOut from "@/components/views/Listing/InfoForGuests/CheckInOut";
import UnsavedChangesModal from "@/components/UI/Popups/Modal/AppUnsavedChangesModal";
import { useUnsavedModal } from "@/use/unsavedModal";

export default {
  name: "InfoForGuests",
  components: {
    CheckInOut,
    WelcomeLetter,
    UnsavedChangesModal
  },
  setup() {
    const route = useRoute();
    const isEditProductMode = !!route.params.id;

    const detailsSectionData = ref({
      checkInOut: null,
      welcomeLatter: null
    });

    const setSectionsData = (section, value) => {
      detailsSectionData.value[section] = value;
    };

    const {
      checkForUnsavedSections,
      setSectionsValidity,
      saveUnsavedSections,
      isInvalidSectionsPresent,
      unsavedSections,
      isModalOpen,
      moveToNextRoute,
      cancelModal
    } = useUnsavedModal(['checkInOut']);

    const save = () => {
      const preparedData = {
        check_in: detailsSectionData.value.checkInOut.checkIn,
        check_out: detailsSectionData.value.checkInOut.checkOut,
        emailDescription: detailsSectionData.value.welcomeLatter
      };

      saveUnsavedSections(preparedData, isEditProductMode);
    }

    return {
      checkForUnsavedSections,
      setSectionsValidity,
      isInvalidSectionsPresent,
      unsavedSections,
      isModalOpen,
      moveToNextRoute,
      cancelModal,
      setSectionsData,
      save
    }
  }
};
</script>

<style scoped>
</style>
