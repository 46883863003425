<template>
  <div
    class="relative h-full flex flex-col rounded-lg overflow-hidden
           shadow-lg hover:shadow-xl md:transform md:hover:-translate-y-1 duration-200 group
           transition ease-in-out duration-150">
    <div>
      <div class="flex-shrink-0 z-0 relative w-full aspect-w-16 aspect-h-9">
        <img :src="detail.img">
      </div>
    </div>
    <div
      :class="['bg-white min-h-48']">
      <div class="relative flex-1 bg-white p-5 pb-4 flex flex-col justify-between">
        <div class="flex-1">
          <p
            data-test="detail-title-listing"
            class="text-lg leading-5 font-semibold text-starryNight">
            {{ detail.title }}
          </p>
          <p
            data-test="detail-description-listing"
            class="mt-4 text-sm text-gray-600 line-clamp-4">
            {{ detail.description }}
          </p>
        </div>
        <div class="flex justify-end mt-10">
          <div
            class="self-center block items-center px-2.5 py-1.5 border border-gray-300
                         shadow-sm text-xs font-medium rounded text-starryNight bg-white hover:bg-gray-50
                         focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlight-500">
            from
            <span
              data-test="detail-price-listing"
              class="font-bold">
              {{ `${detail.price} ${detail.currency}` }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListingPreview',
  props: {
    detail: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
